export default {
    Account: {
        Title: {
            Expense: '账单',
            Transaction: '收支明细',
            Pay: '订单支付',
        },
        ExpenseTable: {
            TH1: '账期',
            TH2: '产品',
            TH3: '账单类型',
            TH4: '账单时间',
            TH5: '金额',
            TH6: '应付金额',
            TH7: '状态',
            TH8: '账单号',
            TH9: '订单号',
        },
        TransactionTable: {
            TH1: '账期/类型',
            TH2: '交易号/交易时间',
            TH3: '收支类型',
            TH4: '订单号/账单号',
            TH5: '交易渠道/渠道流水号',
            TH6: '交易金额',
            TH7: '余额',
            TH8: '备注',
            TH9: '交易类型',
            TH10: '账户变动',
        },
        Payment: {
            H1: '订单编号',
            H2: '产品应用',
            H3: '应付金额',
            H4: '支付方式',
            Hint: {
                PaySuccess: '支付成功',
                Ready: '请选择支付方式',
                AliPay: '请使用 支付宝 扫码支付',
                WeChatPay: '请使用 微信 扫码支付',
            },
        }
    }
}